import { format, formatISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { isEmpty } from 'ramda';
import { Link } from 'react-router-dom';
import { SupplyPeriodAttributeProps, Widget } from '../Dashboard';
import { generateCategories } from './BillingReadiness';
import { useBillingReadiness } from '../../billing/billingReadinessApi';
import {
  FieldOption,
  generateDateOptions,
  marketEndOfMonth,
  marketStartOfMonth,
  newMarketDate,
} from '../../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';

type BillingReadinessWidgetProps = SupplyPeriodAttributeProps;

const BillingReadinessWidget = (props: BillingReadinessWidgetProps) => {
  const {
    inc6972UseLimit1OnReadinessWidget, // LD Client Key is inc-6972-use-limit-1-on-readiness-widget
  } = useFlags();
  const {
    inc6972UseTotalsOnlyOnReadinessWidget, // LD Client Key is inc-6972-use-totals-only-on-readiness-widget
  } = useFlags();
  const totalsOnly = inc6972UseTotalsOnlyOnReadinessWidget;
  const options = useMemo(() => generateDateOptions(true, 24), []);
  const categories = useMemo(() => generateCategories(), []);
  const { supplyPeriodAttribute, supplyPeriodAttributeURLSearchParams } = props;

  const initialDate = (options.slice(1).shift() as FieldOption).value;
  const [date, setDate] = useState<Date>(new Date(initialDate));

  // billing api works with UTC dates so we convert to match and the endOfMonth
  // method is 1 ms before the next day, but we want to add that back on, so
  // when we convert to UTC it includes the correct billing end date/time
  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useBillingReadiness({
    id: '',
    startDate: marketStartOfMonth(date).toISOString(),
    endDate: marketEndOfMonth(date).toISOString(),
    totalsOnly: totalsOnly,
    attributes: supplyPeriodAttribute,
    // using limit 1 has no apparent impact to the totals field in the response but
    // means that items (which is unused by this component) will only contain 1 item
    // and this saves the backend from having to do all the work to render charge sets
    // (which is quite intensive, involved api calls, and causes timeouts)
    limit: inc6972UseLimit1OnReadinessWidget ? 1 : undefined,
  });

  return (
    <Widget
      className="apl-pb"
      headerComponent={
        <>
          <h3 className="widget__title widget__title--inline apl-h3">
            Billing readiness
            <select
              className="apl-select-v1_0"
              onChange={(event) => setDate(newMarketDate(event?.target.value))}
              value={formatISO(date, { representation: 'date' })}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </h3>
        </>
      }
    >
      {isLoading && <p className="apl-px">Loading...</p>}
      {isError && <p className="apl-px">Sorry, there was an error.</p>}
      {data && (
        <ul className="widget__list">
          {categories.map((category) => (
            <li className="widget__list-item" key={category.name}>
              <Link
                className="widget__list-link apl-color-primary"
                to={`/dashboard/billing-readiness/${category.url}?date=${format(
                  date,
                  'yyyy-MM-dd'
                )}${
                  isEmpty(supplyPeriodAttributeURLSearchParams)
                    ? ''
                    : '&' + supplyPeriodAttributeURLSearchParams
                }`}
              >
                {category.name}
              </Link>
              <span>
                {data.totals.find((item: { reason: string }) => item.reason === category.enum)
                  ?.total ?? '-'}
              </span>
            </li>
          ))}
        </ul>
      )}
    </Widget>
  );
};

export default BillingReadinessWidget;
