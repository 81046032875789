import { formatISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Widget } from '../Dashboard';
import AuthorizedAction from '../../common/AuthorizedAction';
import { useConnections } from '../../connections/connectionsApi';
import {
  RatingCalculationPageResponse,
  RatingsQueryParams,
  RatingStatus,
  useRatings,
} from '../../rating-calculator/ratingsApi';
import { Permission } from '../../../auth/getPermissions';
import {
  FieldOption,
  formatTimestamp,
  generateDateOptions,
  marketEndOfMonth,
  marketStartOfMonth,
} from '../../../util/helper-func';
import { RatingCalculation } from 'api/openapi/rating-calculator';

const FailedRatingRequestsWidget = () => {
  let notRequestedBy: string[] = [];

  const options = useMemo(() => generateDateOptions(true, 24), []);

  const sortBy = 'requestedAt.desc';
  const limit = 5;
  const status: RatingStatus[] = ['urn:flux:rating:request:status:failed'];

  const initialDate = (options.slice().shift() as FieldOption).value;
  const [date, setDate] = useState<Date>(new Date(initialDate));

  const requestedAtFrom = formatISO(marketStartOfMonth(date));
  const requestedAtTo = formatISO(marketEndOfMonth(date));

  const navigate = useNavigate();

  notRequestedBy = [
    ...notRequestedBy,
    'urn:flux:service:billing:unbilled-invoices-trigger',
    'urn:flux:service:rating:best-offer-trigger',
  ];

  const params: RatingsQueryParams = {
    requestedAtTo,
    limit,
    sortBy,
    requestedAtFrom,
    status,
    notRequestedBy,
    purpose: 'urn:flux:rating:purpose:billable',
  };

  params.omitFailedWithSubsequentSuccess = true;
  params.omitInProgressWithSubsequentSuccess = true;
  params.omitInProgressReceivedLessThanSecondsAgo = 900;

  const { data, isError, isInitialLoading: isLoading } = useRatings(params);

  const { data: connectionData } = useConnections();

  return (
    <Widget
      headerComponent={
        <>
          <h3 className="widget__title widget__title--inline apl-h3">
            Failed rating requests
            <select
              className="apl-select-v1_0"
              onChange={(event) => setDate(new Date(event?.target.value))}
              value={formatISO(date, { representation: 'date' })}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </h3>
        </>
      }
      width="default"
    >
      {isLoading && <p className="apl-px">Loading...</p>}
      {isError && <p className="apl-px">Sorry, there was an error.</p>}
      {data && data?.items && (
        <>
          <div className="widget__list">
            {data.items.length > 0 &&
              (data as RatingCalculationPageResponse).items.map((item, index) => {
                const id = item.context.scopedTo.split(':').pop();
                const connectionId =
                  connectionData &&
                  connectionData.find((c: RatingCalculation) => c.id === id)?.connectionId;

                const { errors } = item;
                let error;

                if (errors && errors.length) {
                  try {
                    error = '- ' + errors.join('\n- ');
                  } catch (e) {
                    console.log(e);
                  }
                }

                return (
                  <div
                    className="widget__list-item apl-display-flex apl-flex-row apl-justify-content-between apl-align-items-start"
                    key={`failed-rating-request-${index}`}
                  >
                    <div>
                      <p className="apl-my-none">
                        <strong>{connectionId ?? id}</strong>
                      </p>
                      <p className="apl-my-none" style={{ whiteSpace: 'pre-line' }}>
                        {error}
                      </p>
                    </div>
                    <span style={{ color: 'var(--muted-color)' }}>
                      {formatTimestamp(item.context.requestedAt)}
                    </span>
                  </div>
                );
              })}
            {data.items.length === 0 && (
              <div className="widget__list-item">
                <p>No failed rating requests found.</p>
              </div>
            )}
          </div>
          <div className="apl-display-flex apl-flex-row apl-justify-content-between apl-align-items-center apl-p">
            <AuthorizedAction
              onClick={() =>
                navigate(
                  `/dashboard/failed-rating-requests?date=${formatISO(date, {
                    representation: 'date',
                  })}`
                )
              }
              permission={Permission.CONNECTION_VIEW}
              removeDefaultWeight={true}
            >
              View all failed rating requests
            </AuthorizedAction>
            <span>
              Showing {data?.items.length} of {data?.totalCount}
            </span>
          </div>
        </>
      )}
    </Widget>
  );
};

export default FailedRatingRequestsWidget;
