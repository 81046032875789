import { useQueries, useQuery } from '@tanstack/react-query';
import { UnitValue } from './billingInvoicesApi';
import apiClient from '../../api/apiClient';

const baseEndpoint = '/billing/readiness';

export interface Period {
  startsAt: string;
  endsAt: string;
}

export type BillingReadinessStatus =
  | 'urn:flux:billing:readiness:ready'
  | 'urn:flux:billing:readiness:not_ready'
  | 'urn:flux:billing:readiness:billed';

export interface BillingReadinessCharge {
  createdDate: string;
  period: Period; // charge period
  href: string;
  total: {
    currency: string;
    amount: UnitValue;
  };
  count: number;
  preferred: boolean;
  invoiceNo?: string;
  isCustomerGenerated: boolean;
  ratingResultType?: string;
  latestCreditNote?: {
    id: string;
    title: string;
  };
  latestInvoice?: {
    id: string;
    title: string;
  };
  ratingEngine?: string;
}

export interface BillingReadiness {
  charges: BillingReadinessCharge[];
  connection: string;
  externalSupplyAgreementId: string;
  messages: string[];
  period: Period; // billing period
  ready: BillingReadinessStatus;
}

export const useBillingReadinessDetails = (id: string) => {
  return useQuery(['billing-readiness', id], async () => {
    return apiClient('billing').get(`${baseEndpoint}/${id}`);
  });
};

export type NotReadyEnum =
  | 'urn:flux:billing:readiness:not_ready:on_hold'
  | 'urn:flux:billing:readiness:not_ready:missing_charges'
  | 'urn:flux:billing:readiness:not_ready:estimated_usage'
  | 'urn:flux:billing:readiness:not_ready:non_final_spot_prices';

export interface NotReadyTotal {
  reason: NotReadyEnum;
  total: number;
}

export interface BillingReadinesses {
  total: number;
  totals: NotReadyTotal[];
  items: BillingReadiness[];
  _links: HateosLink[];
}

export interface HateosLink {
  href: string;
  rel: string;
}

export interface BillingReadinessParams {
  id: string | undefined;
  startDate: string;
  endDate: string;
  totalsOnly: boolean;
  attributes?: string[];
  externalSupplyAgreementIds?: string[];
  limit?: number;
}

export const useBillingReadiness = (
  params: BillingReadinessParams,
  options?: Record<string, unknown>
) => {
  const { id, startDate, endDate, totalsOnly, attributes, externalSupplyAgreementIds, limit } =
    params;

  return useQuery(
    ['billing-readiness', startDate, endDate, totalsOnly, attributes, externalSupplyAgreementIds],
    async () => {
      const query = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
      });
      attributes?.forEach((atr) => query.append('attributes', atr));
      externalSupplyAgreementIds?.forEach((id) => query.append('externalSupplyAgreementIds', id));
      query.set('totals_only', String(totalsOnly));

      if (id && id !== '') {
        query.set('connection', id);
      }

      if (limit) {
        query.set('limit', `${limit}`);
      }

      return apiClient('billing').get(`${baseEndpoint}?${query}`);
    },
    {
      ...options,
    }
  );
};

interface BillingReadinessNotReadyReason {
  startDate: string;
  endDate: string;
  notReady: string;
  id?: string;
  offset?: number;
  limit?: number;
  attributes?: string[];
}

export const useParallelBillingReadiness = (reasons: BillingReadinessNotReadyReason[]) => {
  return useQueries({
    queries: reasons.map((reason) => {
      const { endDate, id, limit, notReady, offset, startDate, attributes } = reason;

      const params = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
        not_ready: notReady,
      });

      if (id) {
        params.set('connection', id);
      }

      if (offset) {
        params.set('offset', `${offset}`);
      }

      if (limit) {
        params.set('limit', `${limit}`);
      }

      if (attributes) {
        params.set('attributes', `${attributes}`);
      }

      return {
        queryKey: [
          'billing-readiness',
          startDate,
          endDate,
          notReady,
          id,
          limit,
          offset,
          attributes,
        ],
        queryFn: () => apiClient('billing').get(`${baseEndpoint}?${params.toString()}`),
      };
    }),
  });
};
