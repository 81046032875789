import React, { useState } from 'react';
import { format, isAfter, subSeconds } from 'date-fns';
import { Widget } from '../dashboard/Dashboard';
import EmptyTableRow from '../common/EmptyTableRow';
import { FieldOption, generateDateOptions, newMarketDate } from '../../util/helper-func';
import { MetricConfigEnum, Metric, useGetMetricData } from './metricsApi';
import { buildExternalIdentifier } from './metricHelpers';
import AuthorizedAction from '../common/AuthorizedAction';
import { Permission } from '../../auth/getPermissions';
import EditMetric from './EditMetric';
import DeleteMetric from './DeleteMetric';
import AddMetric from './AddMetric';
import Modal from 'react-modal';
import { modalStyles } from '../layout/Modal';
import { useListData } from '../../list-data/ListDataContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MetricDataComponentProps {
  connectionId: string | undefined;
  contractedPartyId: string | undefined;
}

const HistoricalPeakDemandComponent = ({
  connectionId,
  contractedPartyId,
}: MetricDataComponentProps) => {
  const [historicalMeasureList] = useListData(['HISTORICAL_MEASURE']);
  const historicalMeasureOptions = historicalMeasureList?.data as FieldOption[];
  const initialFrom = genMonthsRange(MetricConfigEnum.metricMonthsRange, new Date());
  const [from, setFrom] = setFromValues();
  const [to, setTo] = setToValues();
  const [addMetricIsOpen, setAddMetricIsOpen] = useState(false);
  const closeAddMetric = () => setAddMetricIsOpen(false);
  const openAddMetric = () => setAddMetricIsOpen(true);

  function setFromValues() {
    return useState<string>(new Date(initialFrom).toISOString());
  }

  function setToValues() {
    const today: Date = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    today.setDate(lastDay);
    today.setHours(0, 0, 0, 0);
    return useState<string>(today.toISOString());
  }

  const initialFromOptions: FieldOption[] = generateDateOptions(
    true,
    MetricConfigEnum.metricMonthsRange,
    'MMMM yyyy',
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date(initialFrom)
  );
  const initialToOptions: FieldOption[] = generateDateOptions(
    false,
    MetricConfigEnum.metricMonthsRange,
    'MMMM yyyy',
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date()
  );

  const [fromOptions] = useState<FieldOption[]>(initialFromOptions);
  const [toOptions] = useState<FieldOption[]>(initialToOptions);

  function genMonthsRange(numOfMonths: number, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  function getHistoricalMeasureText(type: string | undefined) {
    const historicalMeasureArr: string[] | undefined = type?.split('#');
    let historicalMeasure = '';
    if (historicalMeasureArr !== undefined && historicalMeasureArr.length > 0) {
      historicalMeasure = historicalMeasureArr[1];
    }

    const obj = historicalMeasureOptions.find((label) => label.value === historicalMeasure);
    return obj != null ? obj.label : '';
  }

  function generateTypeTags() {
    const typeArr: string[] = [];
    historicalMeasureOptions?.forEach((hm) =>
      typeArr.push(MetricConfigEnum.metricType + '#' + hm.value)
    );

    return typeArr;
  }

  const metricData = useGetMetricData(
    {
      startsAt: from,
      endsAt: to,
      anyType: generateTypeTags(),
      tags: [MetricConfigEnum.metricConnectionUrn + ':' + connectionId],
      source: MetricConfigEnum.metricSource.toString(),
      externalIdentifier: buildExternalIdentifier(connectionId, contractedPartyId),
    },
    useFlags()
  );

  const hasMetricData =
    metricData && metricData?.data && metricData?.data.items && metricData?.data?.items?.length > 0;
  return (
    <>
      <Widget
        className="widget--full"
        data-testid="historical-peak-demand"
        title="Historical Peak Demand"
        width="full"
      >
        <div className="table-filter apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between">
          <div
            className="apl-display-flex apl-flex-row apl-align-items-center"
            style={{
              paddingLeft: '15px',
            }}
          >
            <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr">
              <label className="apl-field__label apl-mr apl-mb-none" htmlFor="from-field">
                From
              </label>
              <select
                className="apl-select-v1_0"
                data-testid="from date filter dropdown"
                id="from-field"
                name="from"
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                //onChange={(e) => {setFromOptions(setRange(24, new Date(to), true)); setFrom(e.target.value)}}
                value={from}
              >
                {fromOptions.length > 0 &&
                  fromOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
            <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr">
              <label className="apl-field__label apl-mr apl-mb-none" htmlFor="to-field">
                to
              </label>
              <select
                className="apl-select-v1_0"
                data-testid="to date filter dropdown"
                id="to-field"
                name="to"
                onChange={(e) => {
                  setTo(e.target.value);
                }}
                //onChange={(e) => {setToOptions(setRange(24, new Date(from), true));setTo(e.target.value)}}
                value={to}
              >
                {toOptions.length > 0 &&
                  toOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div style={{ paddingRight: '5px' }}>
            <AuthorizedAction onClick={() => openAddMetric()} permission={Permission.METRIC_EDIT}>
              Add New Peak Demand
            </AuthorizedAction>
          </div>
        </div>
        <table
          className="apl-table-v1 apl-width-full is-plain zebra-table"
          style={{ borderRadius: 0 }}
        >
          {hasMetricData && (
            <>
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Demand type</th>
                  <th>Value</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {(metricData.data?.items as Metric[])
                  .sort((a, b) => {
                    const dateA = a?.startsAt ? new Date(a.startsAt) : new Date('1970-01-01');
                    const dateB = b?.startsAt ? new Date(b.startsAt) : new Date('1970-01-01');
                    return isAfter(dateA, dateB) ? -1 : 1;
                  })
                  ?.map((item, index) => {
                    return (
                      <tr
                        className="apl-table-v1__row"
                        data-testid={`historical-peak-demand-row-${index}`}
                        key={index}
                      >
                        <td className="apl-text-left">
                          {format(
                            newMarketDate(item.startsAt), // date received from metrics API is in UTC
                            'd MMM yyyy'
                          )}
                        </td>
                        <td className="apl-text-left">
                          {format(
                            subSeconds(
                              // in displayed (not saved) value, subtract one second to show previous date for end date
                              // so period 01 Jun 00:00:00 to 01 Jul 00:00:00
                              // displays in the UI as 01 Jun to 30 Jun (inclusive range), not to 1 Jul
                              newMarketDate(item.endsAt),
                              1
                            ),
                            'd MMM yyyy'
                          )}
                        </td>
                        <td className="apl-text-left">{getHistoricalMeasureText(item.type)}</td>
                        <td className="apl-text-left">
                          {item.value.value + ` ` + item.value.unit}
                        </td>
                        <td className="apl-display-flex apl-flex-row">
                          <EditMetric
                            metric={item}
                            connectionId={connectionId}
                            contractedPartyId={contractedPartyId}
                          />
                          <DeleteMetric metricId={item.id} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          )}
          {!hasMetricData && <EmptyTableRow message="Sorry, no historical data for this period." />}
        </table>
      </Widget>
      <Modal
        isOpen={addMetricIsOpen}
        onRequestClose={closeAddMetric}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        <AddMetric
          close={closeAddMetric}
          connectionId={connectionId}
          contractedPartyId={contractedPartyId}
        />
      </Modal>
    </>
  );
};

export default HistoricalPeakDemandComponent;
