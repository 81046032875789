import {
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui';
import { callSupplyPeriodsV3 } from 'components/connections/connectionsApi';
import { Widget } from 'components/dashboard/Dashboard';
import Page, { PageHeader } from 'components/layout/Page';
import { useReports } from 'components/rating-calculator/ratingsApi';
import getConfig from 'config/getConfig';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useStore } from 'store';
import { marketEndOfMonth, marketStartOfMonth, newMarketDate } from 'util/helper-func';

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(moment(marketStartOfMonth().toISOString()));
  const [endDate, setEndDate] = useState(moment(marketEndOfMonth().toISOString()));
  const { mutateAsync } = useReports();
  const {
    oci1637EnableReportsScreen, // LD Client Key is OCI-1637-enable-reports-screen
    dysn51RatingEngineReport, // dysn-51-rating-engine-report
  } = useFlags();
  const config = getConfig();
  const rows = [];
  if (config.reportsAvailable.includes('best-offer') && oci1637EnableReportsScreen) {
    rows.push({
      name: 'Best Offer Report',
      description:
        'The report takes a date range, and finds all Flexibill connections that have a best offer calculation during that period. It shows whether those connections are on their best offer, and any estimated annual cost difference.',
      actions: <EuiButton onClick={() => setIsModalVisible(true)}>Generate Report</EuiButton>,
    });
  }
  if (config.reportsAvailable.includes('rating-engine') && dysn51RatingEngineReport) {
    rows.push({
      name: 'Rating Engine Setting for valid supply periods',
      description:
        'This report generates a CSV file detailing the rating engine configured for all supply periods with a valid status. It includes the ICP number, the rating engine (Flux or Gorilla), and the start dates and end dates of each supply period.',
      actions: (
        <EuiButton
          onClick={() => {
            generateEngineCsv();
          }}
        >
          Download CSV
        </EuiButton>
      ),
    });
  }

  return (
    <>
      <PageHeader showBack={true} title="Reports"></PageHeader>
      <Page>
        <Widget>
          <EuiBasicTable
            width="80%"
            items={rows}
            rowHeader="firstName"
            columns={[
              {
                field: 'name',
                name: 'Name',
                width: '22%',
              },
              {
                field: 'description',
                name: 'Description',
                isExpander: true,
              },
              {
                field: 'actions',
                name: 'Actions',
                width: '15%',
                render: (item: any) => {
                  return item;
                },
              },
            ]}
          />
        </Widget>
      </Page>
      {isModalVisible && (
        <EuiOverlayMask>
          <EuiModal onClose={() => setIsModalVisible(false)} initialFocus="[name=popswitch]">
            <EuiModalHeader>
              <EuiModalHeaderTitle>Best Offer Report</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFormRow label="Select a date">
                <EuiDatePicker
                  selected={startDate}
                  dateFormat="DD/MMM/YYYY"
                  onChange={(date: Moment) => {
                    setStartDate(date);
                  }}
                />
              </EuiFormRow>
              <EuiFormRow label="Select a date">
                <EuiDatePicker
                  selected={endDate}
                  dateFormat="DD/MMM/YYYY"
                  onChange={(date: Moment) => {
                    setEndDate(date);
                  }}
                />
              </EuiFormRow>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => setIsModalVisible(false)}>Cancel</EuiButtonEmpty>
              <EuiButton
                onClick={async () => {
                  setIsModalVisible(false);
                  await mutateAsync({
                    requestedFrom: newMarketDate(startDate.add(1, 'd').toISOString()).toISOString(),
                    requestedTo: newMarketDate(endDate.add(1, 'd').toISOString()).toISOString(),
                  });

                  useStore.getState().addNotification({
                    color: 'success',
                    title:
                      'Generation of Flexibill Best Offer Report has been requested. The output file will be made available in the SFTP folder.',
                    id: 'bestOfferGeneration',
                  });
                }}
                fill
              >
                Generate
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </>
  );
};

async function generateEngineCsv() {
  // Should update whole function
  const limit = 500;
  useStore.getState().addNotification({
    title: 'Generating a CSV - this could take a few seconds',
    color: 'primary',
    id: 'csvDownload',
  });
  const firstResponse = await callSupplyPeriodsV3(limit, 0, 'VALID');
  const responseList = firstResponse.items;
  let itemsLeft = firstResponse.totalCount - limit;
  let offset = limit;
  const promises = [];

  while (itemsLeft > 0) {
    promises.push(
      callSupplyPeriodsV3(limit, offset, 'VALID').then((response) =>
        responseList.push(...response.items)
      )
    );
    itemsLeft -= limit;
    offset += limit;
  }

  await Promise.all(promises);
  console.log(responseList);
  const filename = `rating_engine_${new Date().toISOString()}.csv`;
  const csvResult =
    'icp,ratingEngine,supplyPeriodStart,supplyPeriodEnd\n' +
    responseList
      .map((item: any) => {
        return `"${item.externalConnectionId}", "${item.preferredRatingEngine
          .split(':')
          .pop()}", "${item.startsAt}", "${item.endsAt}"`;
      })
      .join('\n');
  const url = URL.createObjectURL(new Blob([csvResult], { type: 'text/csv' }));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
  useStore.getState().addNotification({
    title: 'Csv downloaded',
    color: 'success',
    id: 'csvDownload',
  });
}
