import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Connection,
  SupplyPeriodSummary,
  ChargeComponent,
  ChargeTag,
  useUpdateSupplyperiodV3,
  useUpdateSupplyPeriod,
  SupplyPeriodDetails,
} from '../connectionsApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import Alert, { AlertEnum } from '../../layout/Alert';
import { ErrorText } from '../../layout/Errors';
import { Permission } from '../../../auth/getPermissions';
import FieldWrapper, { FieldWidth } from 'components/form/FieldWrapper';
import {
  EuiBasicTable,
  EuiButton,
  EuiCheckbox,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
} from '@elastic/eui';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ExpectedChargeComponentsFormProps {
  connection: Connection;
  supplyPeriod: SupplyPeriodSummary;
  supplyPeriodV3: SupplyPeriodDetails;
  onCancel: () => void;
  onSubmit: () => void;
}

const ExpectedChargeComponentsForm: FunctionComponent<ExpectedChargeComponentsFormProps> = ({
  connection,
  supplyPeriod,
  supplyPeriodV3,
  onCancel,
  onSubmit,
}) => {
  const {
    inc6546AddFlowDirectionToExpectedChargeComponents, // key is inc-6546-add-flow-direction-to-expected-charge-components
  } = useFlags();
  const allChargeTags: ChargeTag[] = ['NETWORK', 'ENERGY', 'EQUIPMENT', 'LEVY', 'ENERGYFEE'];
  const { error, isLoading, mutateAsync } = useUpdateSupplyperiodV3(supplyPeriod.id, {
    enabled: !!supplyPeriod?.id,
  });

  const [chargeComponents, setChargeComponents] = useState<ChargeComponent[]>([]);

  useEffect(() => {
    supplyPeriodV3.expectedChargeComponents &&
      setChargeComponents(supplyPeriodV3.expectedChargeComponents);
  }, [supplyPeriodV3]);

  function handleSubmit() {
    mutateAsync({
      ...supplyPeriodV3,
      expectedChargeComponents: chargeComponents,
    });
    supplyPeriodV3.expectedChargeComponents = chargeComponents;
    onSubmit();
  }

  const {
    error: errorOld,
    isLoading: isLoadingOld,
    mutateAsync: mutateAsyncOld,
  } = useUpdateSupplyPeriod(connection.id, supplyPeriod.id, {
    enabled: !!connection?.id && !!supplyPeriod?.id,
  });

  const handleSubmitOld = async (data: FormikValues) => {
    const { expectedChargeTags } = data;

    const result = await mutateAsyncOld({
      externalSupplyAgreementId: supplyPeriod.externalSupplyAgreementId,
      contractedParty: supplyPeriod.contractedParty,
      startDate: supplyPeriod.startDate,
      endDate: supplyPeriod.endDate,
      attributes: supplyPeriod.attributes,
      expectedChargeTags,
    });

    if (result) {
      onSubmit();
    }
  };
  return (
    <>
      {inc6546AddFlowDirectionToExpectedChargeComponents ? (
        <>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Edit charge components</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <>
              {error && <Alert type={AlertEnum.DANGER}>Sorry, there was an error</Alert>}
              {!isLoading && (
                <EuiBasicTable
                  items={allChargeTags.map((tag) => {
                    return {
                      tag: tag,
                      flow:
                        chargeComponents.find((component) => component.tag == tag)?.flow ?? 'IN',
                    };
                  })}
                  columns={[
                    {
                      field: 'tag',
                      name: 'Charge Tag',
                      render: (tag: any) => (
                        <EuiCheckbox
                          id={tag}
                          label={
                            tag === 'ENERGYFEE'
                              ? 'Energy fee'
                              : tag.charAt(0) + tag.slice(1).toLowerCase()
                          }
                          checked={!!chargeComponents.find((component) => component.tag == tag)}
                          onChange={() => {
                            if (chargeComponents.find((component) => component.tag == tag)) {
                              setChargeComponents(
                                chargeComponents.filter((component) => component.tag != tag)
                              );
                            } else {
                              setChargeComponents([...chargeComponents, { tag: tag, flow: 'IN' }]);
                            }
                          }}
                        />
                      ),
                    },
                    {
                      field: 'flow',
                      name: 'Flow Direction',
                      render: (flow: string, item: ChargeComponent) => (
                        <EuiSelect
                          id="tag"
                          options={[
                            { text: 'Into Connection', value: 'IN', selected: flow == 'IN' },
                            { text: 'Out Of Connection', value: 'OUT', selected: flow == 'OUT' },
                            { text: 'Both Directions', value: 'BOTH', selected: flow == 'BOTH' },
                          ]}
                          disabled={
                            !chargeComponents.map((component) => component.tag).includes(item.tag)
                          }
                          onChange={(newValue) => {
                            if (chargeComponents.find((component) => component.tag == item.tag)) {
                              const newChargeComponent: ChargeComponent = {
                                tag: item.tag,
                                flow: newValue.target.value as 'IN' | 'OUT' | 'BOTH',
                              };
                              setChargeComponents([
                                ...chargeComponents.filter(
                                  (component) => !(component.tag == item.tag)
                                ),
                                newChargeComponent,
                              ]);
                            }
                          }}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={() => onCancel()}
              disabled={isLoading}
              type="button"
            >
              Cancel
            </EuiButton>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={
                isLoading ||
                chargeComponents == supplyPeriodV3.expectedChargeComponents ||
                supplyPeriodV3.expectedChargeComponents == undefined
              }
              permission={Permission.CONNECTION_EDIT}
              testId="save-connection-button"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </AuthorizedAction>
          </EuiModalFooter>
        </>
      ) : (
        <Formik
          initialValues={{
            expectedChargeTags: supplyPeriod.expectedChargeTags,
          }}
          onSubmit={(data) => handleSubmitOld(data)}
        >
          {({ dirty, isValid }) => (
            <Form className="apl-form-layout-v1">
              <>
                <h2>Edit charge components</h2>
                {errorOld && <Alert type={AlertEnum.DANGER}>Sorry, there was an error</Alert>}
                <FieldWrapper
                  fieldWidth={FieldWidth.FULL}
                  htmlFor="expected-charge-tags"
                  label="Expected charge components:"
                >
                  {allChargeTags.map((tag) => (
                    <div className="apl-checkbox-v1 apl-mb-xs" key={`charge-tag-field--${tag}`}>
                      <Field
                        className="apl-checkbox__input"
                        id={`expected-charge-tag--${tag.toLowerCase()}`}
                        name="expectedChargeTags"
                        type="checkbox"
                        value={tag}
                      />
                      <label
                        className="apl-checkbox__label"
                        htmlFor={`expected-charge-tag--${tag.toLowerCase()}`}
                      >
                        {tag === 'ENERGYFEE'
                          ? 'Energy fee'
                          : tag.charAt(0) + tag.slice(1).toLowerCase()}
                      </label>
                    </div>
                  ))}
                  <ErrorMessage component={ErrorText} name="expectedChargeTags" />
                </FieldWrapper>
                <ErrorMessage component={ErrorText} name="expectedChargeTags" />
                <div className="apl-display-flex apl-justify-content-end">
                  <button
                    data-testid="cancel-button"
                    className="apl-button-v1"
                    onClick={() => onCancel()}
                    disabled={isLoadingOld}
                    type="button"
                  >
                    Cancel
                  </button>
                  <AuthorizedAction
                    extraClasses="is-primary"
                    isDisabled={isLoadingOld || !isValid || !dirty}
                    permission={Permission.CONNECTION_EDIT}
                    testId="save-connection-button"
                    type="submit"
                  >
                    Save
                  </AuthorizedAction>
                </div>
              </>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ExpectedChargeComponentsForm;
