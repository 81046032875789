import React, { FunctionComponent } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { format, isBefore, isDate, addDays, subSeconds, isAfter } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isNil } from 'ramda';
import { array, date, number, object, string } from 'yup';
import AuthorizedAction from '../common/AuthorizedAction';
import { FormRow } from '../form';
import FluxDatePicker from '../form/DatePicker';
import FieldWrapper, {
  FieldWidth,
  inputClassNameBuilder,
  selectClassNameBuilder,
} from '../form/FieldWrapper';
import { ErrorText } from '../layout/Errors';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';
import { MetricConfigEnum, Metric } from './metricsApi';
import { buildExternalIdentifier } from './metricHelpers';
import { FieldOption, newMarketDate } from '../../util/helper-func';
import { useListData } from '../../list-data/ListDataContext';
import getConfig from '../../config/getConfig';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MetricEditFormProps {
  close: () => void;
  error?: ErrorModel;
  metric?: Metric;
  connectionId: string | undefined;
  contractedPartyId: string | undefined;
  submitFunction: (metric: Metric) => Promise<any>;
}

const EditMetricForm: FunctionComponent<MetricEditFormProps> = ({
  close,
  metric,
  connectionId,
  contractedPartyId,
  submitFunction,
}) => {
  const isEdit = !isNil(metric);
  const [historicalMeasureList] = useListData(['HISTORICAL_MEASURE']);
  const historicalMeasureOptions = historicalMeasureList?.data as FieldOption[];
  const metricUOM: string[] = MetricConfigEnum.metricUOMList.split(',');

  const { lomn530UiMetricsDateValidation } = useFlags();
  const compareWithStartDate = new Date();
  compareWithStartDate.setFullYear(compareWithStartDate.getFullYear() - 2);
  const compareWithEndDate = new Date();
  compareWithEndDate.setFullYear(compareWithEndDate.getFullYear() + 2);

  const metricSchema = object().shape({
    id: string().nullable(),
    type: string().nullable(),
    tags: array().nullable(),
    source: string().nullable(),
    startsAt: date()
      .typeError('Must be a valid date')
      .label('Start date')
      .required('Please select a start date')
      .test('2years-ago', 'Metric start date must be within the last 2 years', (val) => {
        return lomn530UiMetricsDateValidation
          ? isDate(val) && isAfter(val as Date, compareWithStartDate)
          : true;
      }),
    endsAt: date()
      .typeError('Must be a valid date')
      .label('End date')
      .required('Please select an end date')
      .test('2years-after', 'Metric end date must within the next 2 years', (val) => {
        return lomn530UiMetricsDateValidation
          ? isDate(val) && isBefore(val as Date, compareWithEndDate)
          : true;
      })
      .when('startsAt', (startsAt: Date, schema: any) => {
        if (isDate(startsAt) && isBefore(startsAt, new Date('9999-12-31'))) {
          return schema.min(startsAt, 'End date must be after start date');
        } else {
          return schema;
        }
      }),
    value: object().shape({
      value: number()
        .label('Figure')
        .required('Please provide a value')
        .typeError('Must be a number')
        .max(100000000),
      unit: string().required('Please select a unit').max(20),
    }),
  });

  function getHistoricalMeasureText(type: string | undefined) {
    const historicalMeasureArr: string[] | undefined = type?.split('#');
    let historicalMeasure = '';
    if (historicalMeasureArr !== undefined && historicalMeasureArr.length > 0) {
      historicalMeasure = historicalMeasureArr[1];
    }
    const obj = historicalMeasureOptions?.find((label) => label.value === historicalMeasure);
    return obj != null ? obj.value : '';
  }

  const initialEndsAt: string =
    isEdit && metric?.endsAt != null
      ? `${format(
          // in displayed (not saved) value, subtract one second to show previous date for end date
          // so period 01 Jun 00:00:00 to 01 Jul 00:00:00
          // displays in the UI as 01 Jun to 30 Jun (inclusive range), not to 1 Jul
          subSeconds(newMarketDate(metric?.endsAt), 1),
          'yyyy-MM-dd'
        )}`
      : '';
  return (
    <Formik
      initialValues={
        {
          value: isEdit
            ? metric?.value
            : {
                value: '',
                unit: metricUOM[0],
              },
          startsAt:
            isEdit && metric?.startsAt != null
              ? `${format(newMarketDate(metric?.startsAt), 'yyyy-MM-dd')}`
              : '',
          endsAt: initialEndsAt,
          id: isEdit ? `${metric?.id}` : '',
          type: isEdit ? `${metric?.type}` : MetricConfigEnum.metricType,
          tags: isEdit ? metric?.tags : [MetricConfigEnum.metricConnectionUrn + `:${connectionId}`],
          source: isEdit ? `${metric?.source}` : MetricConfigEnum.metricSource,
          externalIdentifier: isEdit
            ? `${metric?.externalIdentifier}`
            : buildExternalIdentifier(connectionId, contractedPartyId),
          historicalMeasure: isEdit ? getHistoricalMeasureText(`${metric?.type}`) : '',
        } as unknown as Metric
      }
      onSubmit={(formValues) => {
        const formValuesCopy = { ...formValues }; // create deep copy using spread operator, rather than editing reference
        formValuesCopy.startsAt = zonedTimeToUtc(
          formValuesCopy.startsAt,
          getConfig().marketTimezone
        ).toISOString();
        formValuesCopy.endsAt = addDays(
          zonedTimeToUtc(formValuesCopy.endsAt, getConfig().marketTimezone),
          1
        ).toISOString(); // end date is inclusive of the day
        formValuesCopy.type = MetricConfigEnum.metricType + '#' + formValuesCopy.historicalMeasure;
        delete formValuesCopy.historicalMeasure;

        return submitFunction(formValuesCopy);
      }}
      validationSchema={metricSchema}
    >
      {({ errors, touched, dirty, isSubmitting, isValid }) => (
        <Form className="apl-form-layout-v1">
          <FormRow>
            <FieldWrapper
              htmlFor="historicalMeasure"
              label="Demand type"
              fieldWidth={FieldWidth.HALF}
            >
              <Field
                data-testid="historicalMeasure"
                name="historicalMeasure"
                id="historicalMeasure"
                className={selectClassNameBuilder('historicalMeasure', errors, touched)}
                as="select"
              >
                <option value="" />
                {historicalMeasureOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="historicalMeasure" />
            </FieldWrapper>
            <FieldWrapper
              htmlFor="value.unit"
              label="Unit of measurement"
              fieldWidth={FieldWidth.HALF}
            >
              <Field
                data-testid="value.unit"
                name="value.unit"
                id="value.unit"
                className={selectClassNameBuilder('value.unit', errors, touched)}
                as="select"
              >
                {metricUOM.map((s, index: number) => (
                  <option key={index} value={s}>
                    {s}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="value.unit" />
            </FieldWrapper>
          </FormRow>
          <div>
            <div className="apl-display-flex apl-flex-row apl-mb-l">
              <h3 className="form__subheading apl-mb-none">Record</h3>
            </div>
            <FormRow className="apl-mb apl-flex-nowrap">
              <FieldWrapper fieldWidth={FieldWidth.FULL} htmlFor="value.value" label="Figure">
                <Field
                  autoComplete="off"
                  data-testid="value.value"
                  name="value.value"
                  id="value.value"
                  className={inputClassNameBuilder('value.value', errors, touched)}
                />
                <ErrorMessage component={ErrorText} name="value.value" />
              </FieldWrapper>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="start-date" label="Start date">
                <Field
                  autoComplete="off"
                  data-testid="start-date"
                  name="startsAt"
                  id="start-date"
                  className={inputClassNameBuilder('startsAt', errors, touched)}
                  component={FluxDatePicker}
                />
                <ErrorMessage component={ErrorText} name="startsAt" />
              </FieldWrapper>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="end-date" label="End date">
                <Field
                  autoComplete="off"
                  data-testid="end-date"
                  name="endsAt"
                  id="end-date"
                  className={inputClassNameBuilder('endsAt', errors, touched)}
                  component={FluxDatePicker}
                />
                {errors.endsAt && <ErrorText>{errors.endsAt}</ErrorText>}
              </FieldWrapper>
            </FormRow>
          </div>

          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={isSubmitting || !isValid || (!dirty && isEdit)}
              permission={Permission.METRIC_EDIT}
              testId="add-metric-button"
              type="submit"
            >
              Save
            </AuthorizedAction>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditMetricForm;
