import React, { FunctionComponent } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { isBefore, isDate, addDays, isAfter } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isNil } from 'ramda';
import { array, date, number, object, string } from 'yup';
import AuthorizedAction from '../common/AuthorizedAction';
import { FormRow } from '../form';
import FluxDatePicker from '../form/DatePicker';
import FieldWrapper, {
  FieldWidth,
  inputClassNameBuilder,
  selectClassNameBuilder,
} from '../form/FieldWrapper';
import { ErrorText } from '../layout/Errors';
import { ErrorModel } from '../../api/ErrorDisplay';
import ButtonAsLink from '../form/ButtonAsLink';
import { Permission } from '../../auth/getPermissions';
import { MetricConfigEnum, Metric } from './metricsApi';
import { buildExternalIdentifier } from './metricHelpers';
import { FieldOption } from '../../util/helper-func';
import { useListData } from '../../list-data/ListDataContext';
import getConfig from '../../config/getConfig';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MetricFormProps {
  close: () => void;
  error?: ErrorModel;
  metric?: Metric;
  connectionId: string | undefined;
  contractedPartyId: string | undefined;
  submitFunction: (metric: Metric) => Promise<any>;
}

const setupInitialValues = (defaults: any) => {
  return {
    id: '',
    type: MetricConfigEnum.metricType,
    tags: [MetricConfigEnum.metricConnectionUrn + `:${defaults.connectionId}`],
    source: MetricConfigEnum.metricSource,
    externalIdentifier: [
      buildExternalIdentifier(defaults.connectionId, defaults.contractedPartyId),
    ],
    historicalMeasure: defaults.defaultDemandType,
    unit: '',
    // The Metric contains a single "value" object however the UI allows for adding multiple metric records in one go.
    // As such the "lineRecords" are used to capture those individual record values and then extract them into separate metric objects when
    // Submission is performed
    value: {
      value: 0,
      unit: '',
    },
    lineRecords: [
      {
        figure: 0,
        startsAt: '',
        endsAt: '',
      },
    ],
  } as unknown as Metric;
};

const AddMetricForm: FunctionComponent<MetricFormProps> = ({
  close,
  metric,
  connectionId,
  contractedPartyId,
  submitFunction,
}) => {
  const isEdit = !isNil(metric);
  const [historicalMeasureList] = useListData(['HISTORICAL_MEASURE']);
  const historicalMeasureOptions = historicalMeasureList?.data as FieldOption[];
  const metricUOM: string[] = MetricConfigEnum.metricUOMList.split(',');

  const { lomn530UiMetricsDateValidation } = useFlags();

  const compareWithStartDate = new Date();
  compareWithStartDate.setFullYear(compareWithStartDate.getFullYear() - 2);
  const compareWithEndDate = new Date();
  compareWithEndDate.setFullYear(compareWithEndDate.getFullYear() + 2);

  const metricSchema = object().shape({
    id: string().nullable(),
    type: string().nullable(),
    tags: array().nullable(),
    source: string().nullable(),
    historicalMeasure: string().required('Please select a demand type'),
    unit: string().required('Please select a unit'),
    lineRecords: array().of(
      object({
        startsAt: date()
          .typeError('Must be a valid date')
          .label('Start date')
          .required('Please select a start date')
          .test('2years-ago', 'Metric start date must be within the last 2 years', (val) => {
            return lomn530UiMetricsDateValidation
              ? isDate(val) && isAfter(val as Date, compareWithStartDate)
              : true;
          }),
        endsAt: date()
          .typeError('Must be a valid date')
          .label('End date')
          .required('Please select an end date')
          .test('2years-after', 'Metric end date must within the next 2 years', (val) => {
            return lomn530UiMetricsDateValidation
              ? isDate(val) && isBefore(val as Date, compareWithEndDate)
              : true;
          })
          .when('startsAt', (startsAt: Date, schema: any) => {
            if (isDate(startsAt) && isBefore(startsAt, new Date('9999-12-31'))) {
              return schema.min(startsAt, 'Metric end date must be after the metric start date');
            } else {
              return schema;
            }
          }),
        figure: number()
          .label('Figure')
          .required('Please provide a value')
          .typeError('Must be a number')
          .max(100000000),
      })
    ),
  });

  const getDefaultDemandType = (historicalMeasureOptions: FieldOption[]): string => {
    let matchedHistoricalMeasure = '';
    historicalMeasureOptions?.forEach((label) => {
      if (label.value === MetricConfigEnum.metricDemandTypeDefault) {
        matchedHistoricalMeasure = label.value;
      }
    });
    return matchedHistoricalMeasure;
  };

  const defaultDemandType = getDefaultDemandType(historicalMeasureOptions);

  const defaults = {
    defaultDemandType,
    connectionId,
    contractedPartyId,
  };

  return (
    <Formik
      initialValues={setupInitialValues(defaults)}
      onSubmit={(formValues) => {
        const newMetricList: Metric[] = [];
        formValues.lineRecords?.map((line: any) =>
          newMetricList.push({
            id: '',
            type: MetricConfigEnum.metricType as string,
            tags: [MetricConfigEnum.metricConnectionUrn + `:${connectionId}`],
            source: MetricConfigEnum.metricSource as string,
            externalIdentifier: buildExternalIdentifier(connectionId, contractedPartyId),
            historicalMeasure: formValues.historicalMeasure,
            value: {
              value: line.figure,
              unit: formValues.unit as string,
            },
            startsAt: line.startsAt,
            endsAt: line.endsAt,
            lineRecords: null,
            unit: '',
          })
        );

        // submit MarketZoned date i.e 01-01-2024 AEST
        newMetricList?.map((metric: Metric) => {
          metric.startsAt = zonedTimeToUtc(
            metric.startsAt,
            getConfig().marketTimezone
          ).toISOString();
          metric.endsAt = addDays(
            zonedTimeToUtc(metric.endsAt, getConfig().marketTimezone),
            1
          ).toISOString(); // end date is inclusive of the day
          metric.type =
            metric.type.indexOf('#') < 0
              ? metric.type + '#' + metric.historicalMeasure
              : metric.type;
          delete metric?.unit;
          delete metric.id;
          delete metric?.lineRecords;
          delete metric?.historicalMeasure;

          return submitFunction(metric);
        });
      }}
      validationSchema={metricSchema}
    >
      {({ values, errors, touched, dirty, isSubmitting, isValid }) => (
        <Form className="apl-form-layout-v1">
          <FormRow>
            <FieldWrapper
              htmlFor="historicalMeasure"
              label="Demand type"
              fieldWidth={FieldWidth.HALF}
            >
              <Field
                data-testid="historicalMeasure"
                name="historicalMeasure"
                id="historicalMeasure"
                className={selectClassNameBuilder('historicalMeasure', errors, touched)}
                as="select"
              >
                <option value="" />
                {historicalMeasureOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="historicalMeasure" />
            </FieldWrapper>
            <FieldWrapper htmlFor="unit" label="Unit of measurement" fieldWidth={FieldWidth.HALF}>
              <Field
                data-testid="unit"
                name="unit"
                id="unit"
                className={selectClassNameBuilder('unit', errors, touched)}
                as="select"
              >
                <option value="" />
                {metricUOM.map((s, index: number) => (
                  <option key={index} value={s}>
                    {s}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="unit" />
            </FieldWrapper>
          </FormRow>
          <FieldArray
            name="lineRecords"
            render={(arrayHelpers) => (
              <div>
                <div className="apl-display-flex apl-flex-row apl-mb-l">
                  <h3 className="form__subheading apl-mb-none">Record</h3>
                  <ButtonAsLink
                    data-testid="add-metric-record"
                    disabled={false}
                    type="button"
                    className="apl-my-m apl-ml-s"
                    onClick={() => {
                      arrayHelpers.push({
                        startsAt: '',
                        endsAt: '',
                        figure: 0,
                      });
                    }}
                  >
                    Add another record
                  </ButtonAsLink>
                </div>
                {values.lineRecords.map((r: any, index: number) => (
                  <FormRow key={index} className="apl-mb apl-flex-nowrap">
                    <FieldWrapper
                      fieldWidth={FieldWidth.FULL}
                      htmlFor={`figure-${index}`}
                      label="Figure"
                    >
                      <Field
                        autoComplete="off"
                        data-testid={`figure-${index}`}
                        name={`lineRecords.${index}.figure`}
                        id={`figure-${index}`}
                        className={inputClassNameBuilder(`figure-${index}`, errors, touched)}
                      />
                      <ErrorMessage component={ErrorText} name={`lineRecords.${index}.figure`} />
                    </FieldWrapper>
                    <FieldWrapper
                      fieldWidth={FieldWidth.HALF}
                      htmlFor={`start-date-${index}`}
                      label="Start date"
                    >
                      <Field
                        autoComplete="off"
                        data-testid={`start-date-${index}`}
                        name={`lineRecords.${index}.startsAt`}
                        id={`start-date-${index}`}
                        className={inputClassNameBuilder(`startsAt-${index}`, errors, touched)}
                        component={FluxDatePicker}
                      />
                      <ErrorMessage component={ErrorText} name={`lineRecords.${index}.startsAt`} />
                    </FieldWrapper>
                    <FieldWrapper
                      fieldWidth={FieldWidth.HALF}
                      htmlFor={`end-date-${index}`}
                      label="End date"
                    >
                      <Field
                        autoComplete="off"
                        data-testid={`end-date-${index}`}
                        name={`lineRecords.${index}.endsAt`}
                        id={`end-date-${index}`}
                        className={inputClassNameBuilder(`endsAt-${index}`, errors, touched)}
                        component={FluxDatePicker}
                      />
                      {lomn530UiMetricsDateValidation ? (
                        <ErrorMessage component={ErrorText} name={`lineRecords.${index}.endsAt`} />
                      ) : (
                        errors.endsAt && <ErrorText>{`lineRecords.${index}.endsAt`}</ErrorText>
                      )}
                    </FieldWrapper>

                    <div
                      style={{
                        marginRight: '-16px',
                        textAlign: 'right',
                        width: '30px',
                      }}
                    >
                      {index !== 0 && (
                        <button
                          disabled={false}
                          onClick={() => arrayHelpers.remove(index)}
                          className="form__delete-rate"
                          type="button"
                        >
                          <span className="material-icons" style={{ fontSize: '18px' }}>
                            close
                          </span>
                        </button>
                      )}
                    </div>
                  </FormRow>
                ))}
              </div>
            )}
          />
          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={isSubmitting || !isValid || (!dirty && isEdit)}
              permission={Permission.METRIC_EDIT}
              testId="add-metric-button"
              type="submit"
            >
              Save
            </AuthorizedAction>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddMetricForm;
