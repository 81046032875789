import React, { FunctionComponent, useEffect, useState } from 'react';
import { useGetConnectionErrors } from '../../rating-config/ratingConfigApi';
import { Widget } from '../../dashboard/Dashboard';
import cx from 'classnames';
import { formatTimestamp } from '../../../util/helper-func';
import {
  RatingCalculationPageResponse,
  RatingsQueryParams,
  RatingStatus,
  useRatings,
} from '../../rating-calculator/ratingsApi';
import ConnectionPageError, { mappedConnectionPageErrors } from './ConnectionPageError';
import { PublishedError } from '../../errors/errorsApi';
import { useErrorTranslation } from '../../../hooks';
import { getCategoryLabelByUrn } from '../../errors/errorCategories';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addDays } from 'date-fns';

interface ConnectionErrorsComponentProps {
  connectionId: string;
}

function getStartOfMonth(date: Date): Date {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  startOfMonth.setHours(0, 0, 0, 0);
  return startOfMonth;
}

const today = new Date();
const startOfTheMonth = getStartOfMonth(today);
const requestedAtTo = today.toISOString();
const requestedAtFrom = startOfTheMonth.toISOString();

const ConnectionErrorsWidget: FunctionComponent<ConnectionErrorsComponentProps> = ({
  connectionId,
}) => {
  const { translateError } = useErrorTranslation();

  const { apex196Apex192CallConnectionErrorsV3 } = useFlags();

  // We are adding 1 day here to accommodate the v2 version of the connection error endpoint being
  //  in terms of dates and not zoned date-times. Once we remove v2, we can stop adding this day.
  //
  // We could remove it now and supply a different endDate to the different versions, but that
  //  would be more complicated, and the end date simply needs to be >= "now". Adding a day is
  //  harmless for this use case for version 3, and necessary for v1 -- so just do it for both for
  //  simplicity.
  const endOfErrorRequestWindow = addDays(today, 1);

  // Fetching connection errors.
  const [connectionPageErrors, setConnectionPageErrors] = useState<ConnectionPageError[]>([]);
  const {
    data: publishedErrors,
    isError: publishedErrorsError,
    isInitialLoading: publishedErrorsLoading,
    isSuccess: publishedErrorsIsSuccess,
  } = useGetConnectionErrors(
    connectionId,
    startOfTheMonth,
    endOfErrorRequestWindow,
    apex196Apex192CallConnectionErrorsV3
  );

  // Fetching rating errors.
  const limit = 5;
  const status: RatingStatus[] = ['urn:flux:rating:request:status:failed'];
  const sortBy = 'requestedAt.desc';
  const ratingsQueryParams: RatingsQueryParams = {
    requestedAtTo,
    requestedAtFrom,
    limit,
    connectionId,
    status,
    sortBy,
  };
  const {
    data: ratingErrors,
    isError: ratingErrorsError,
    isInitialLoading: ratingErrorsLoading,
    isSuccess: ratingErrorsIsSuccess,
  } = useRatings(ratingsQueryParams);

  // Coalescing connection and rating errors.
  useEffect(() => {
    if (ratingErrorsIsSuccess || publishedErrorsIsSuccess) {
      if (publishedErrors) {
        publishedErrors.forEach((error) => {
          error.message = translateError(error as PublishedError);
          error.source = getCategoryLabelByUrn(error.urn);
        });
      }
      setConnectionPageErrors(
        mappedConnectionPageErrors(
          (ratingErrors as RatingCalculationPageResponse)?.items,
          ratingErrorsIsSuccess,
          publishedErrors as PublishedError[],
          publishedErrorsIsSuccess,
          limit
        )
      );
    }
  }, [publishedErrorsIsSuccess, ratingErrorsIsSuccess]);

  return (
    <Widget
      headerComponent={
        <>
          <h3
            className="widget__title widget__title--inline apl-h3"
            style={{ marginBottom: '0px' }}
          >
            Errors for this connection
          </h3>
        </>
      }
      width="default"
    >
      <div className="widget__list">
        {(publishedErrorsError || ratingErrorsError) && (
          <p className="widget__list-item">Sorry, there was an error.</p>
        )}
        {(publishedErrorsLoading || ratingErrorsLoading) && (
          <p className="widget__list-item">Loading...</p>
        )}
        {connectionPageErrors &&
          connectionPageErrors.length > 0 &&
          connectionPageErrors.map((item, i: number) => {
            const errorCategory = item.title ? item.title : 'Other';

            return (
              <div
                className={cx(
                  'widget__list-item',
                  'apl-display-flex',
                  'apl-flex-row',
                  'apl-justify-content-between',
                  'apl-align-items-start'
                )}
                key={`error-${i}`}
              >
                <div style={{ width: '100%' }}>
                  <p className="apl-my-none">
                    <strong>{errorCategory ?? ''}</strong>
                  </p>
                  <p
                    className={cx('apl-my-none', 'apl-pr')}
                    style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                  >
                    {item?.body
                      ? `${item.body.replaceAll(/\{\{.*\}\}/g, '<Not Supplied>')}`
                      : '<Not Supplied>'}
                  </p>
                </div>
                <span
                  style={{
                    color: 'var(--muted-color)',
                    width: '150px',
                    padding: '10px',
                    paddingTop: '0px',
                    textAlign: 'right',
                  }}
                >
                  {formatTimestamp(item.date)}
                </span>
              </div>
            );
          })}
        {publishedErrors &&
          publishedErrors.length === 0 &&
          ratingErrors &&
          ratingErrors.totalCount === 0 && (
            <p className="widget__list-item">There are no errors.</p>
          )}
      </div>
    </Widget>
  );
};

export default ConnectionErrorsWidget;
